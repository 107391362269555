import p1_img from "./t-shrit.png"
import p2_img from "./white shoes.png"

let data_product = [
    {
        id: 1,
        name: "T-shrit",
        category: "shrits",
        image_urls: p1_img,
        image_2: p2_img,
        image_3: p1_img,
        image_4: p2_img,
        new_price: 50.0,
        old_price: 80.5,
    },
    {
        id: 2,
        name: "T-shrit",
        category: "shrits",
        image_urls: p1_img,
        image_2: p2_img,
        image_3: p1_img,
        image_4: p2_img,
        new_price: 50.0,
        old_price: 80.5,
    },
    {
        id: 3,
        name: "T-shrit",
        category: "shrits",
        image_urls: p1_img,
        image_2: p2_img,
        image_3: p1_img,
        image_4: p2_img,
        new_price: 50.0,
        old_price: 80.5,
    },
    {
        id: 4,
        name: "T-shrit",
        category: "shrits",
        image_urls: p1_img,
        image_2: p2_img,
        image_3: p1_img,
        image_4: p2_img,
        new_price: 50.0,
        old_price: 80.5,
    },
];

export default data_product;