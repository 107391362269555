import React, { useContext } from 'react'
import'./css/ShopCategory.css'
import {ShopContext} from '../Context/ShopContext'
import Item from '../Components/Item/Item'
import arrow_down from '../Components/Assets/arrowdown.svg'

const ShopCategory = (props) => {

  const {all_product} = useContext(ShopContext)

  return (
    <div className='shop-category'>
      <img className='shopcategory-banner' src={props.banner} alt="banner" />
      <div className="shopcategory-indexsort">
        <p><span>Showing 1-12</span> out of 36 products</p>
        <div className="shopcategory-sort">
          sort by <img src={arrow_down} alt="arrow"/>
        </div>
      </div>
      <div className="shopcategory-products">
        {all_product.map((item,i)=>{
          if(props.category===item.category){
            return <Item  key={i} id={item.id} name={item.name} image_urls={item.image_urls[0]} new_price={item.new_price} old_price={item.old_price}/>
          }
          else{
            return null;
          }
        })}
      </div>
      <div className="shopcategory-loadmore">
        Explore More
      </div>
    </div>
  )
}

export default ShopCategory