import React from 'react'
import './Breakcrums.css'
import arrow_icon from '../Assets/arrow.svg'

const Breakcrums = (props) => {

    const {product} = props;

  return (
    <div className='breakcrum'>
        HOME <img src={arrow_icon} alt="arrow" /> SHOP <img src={arrow_icon} alt="arrow" /> {product.category} <img src={arrow_icon} alt="arrow" /> {product.name}
    </div>
  )
}

export default Breakcrums
