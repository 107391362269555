import React, { useContext, useState } from 'react'
import './Addresscheckout.css'
import { ShopContext } from '../../Context/ShopContext'

const Addresscheckout = () => {

  const { gettotalcartamount, cart } = useContext(ShopContext);
  const [formdata, setfromdata] = useState({
    name: "",
    email: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    number: "",
    cartdata: cart,
  })

  const changehandler = (e) => {
    setfromdata({ ...formdata, [e.target.name]: e.target.value })
  }

  const order = async () => {
    // let responsedata;
    // await fetch('http://localhost:4000/orders',{
    //   method:'POST',
    //   headers:{
    //     Accept:'application/form-data',
    //     'Content-Type':'application/json',
    //   },
    //   body: JSON.stringify(formdata),
    // }).then((response)=> response.json()).then((data)=>responsedata=data)
    // if(responsedata){
    //   alert("order are succesfully order")
    // }
    const response = await fetch('http://localhost:4000/orders', {
      method: 'POST',
      body: JSON.stringify({
        amount: gettotalcartamount() * 100,
        currency: "INR",
        receipt: "qwsa1",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    console.log(order);
    const options = {
      "key": "rzp_test_NS584qlnXj96z0",
      amount: gettotalcartamount() * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Acme Corp",
      "description": "Test Transaction",
      "image": "https://example.com/your_logo",
      "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": async function (response) {
        const body = {
          ...response,
        };

        const validateres = await fetch('http://localhost:4000/order/validate',{
          method: 'POST',
          body: JSON.stringify(body),
          headers:{
            "Content-Type": "application/json",
          },
        });
        const jsonres = await validateres.json();
        console.log(jsonres);
      },
      "prefill": {
        "name": "Gaurav Kumar",
        "email": "gaurav.kumar@example.com",
        "contact": "9000090000"
      },
      "notes": {
        "address": "Razorpay Corporate Office"
      },
      "theme": {
        "color": "#3399cc"
      }
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    order();
  };

  return (
    <form className='place-order' onSubmit={handleSubmit}>
      <div className="place-order-left">
        <p className='tittle'>Delivery Information</p>
        <div className="mutli-field">
          <input onChange={changehandler} value={formdata.name} type="text" placeholder='Name' name='name' />
        </div>
        <div className="mutli-field">
          <input onChange={changehandler} value={formdata.email} type="email" placeholder='Email Address' name='email' />
        </div>
        <div className="mutli-field">
          <input onChange={changehandler} value={formdata.street} type="text" placeholder='Street' name='street' />
        </div>
        <div className="mutli-field">
          <input onChange={changehandler} value={formdata.city} type="text" placeholder='City' name='city' />
          <input onChange={changehandler} value={formdata.state} type="text" placeholder='State' name='state' />
        </div>
        <div className="mutli-field">
          <input onChange={changehandler} value={formdata.zipcode} type="number" placeholder='Zip Code' name='zipcode' />
          <input onChange={changehandler} value={formdata.country} type="text" placeholder='Country' name='country' />
        </div>
        <div className="mutli-field">
          <input onChange={changehandler} value={formdata.number} type="number" placeholder='Phone Number' name='number' />
        </div>
      </div>
      <div className="place-prder-right">
        <div className="cart-total-place">
          <h1>cart Totals</h1>
          <div>
            <div className="cartitems-total-item-place">
              <p>Subtotal</p>
              <p>₹{gettotalcartamount()}</p>
            </div>
            <hr />
            <div className="cartitems-total-item-place">
              <p>Shipping fee</p>
              <p>Free</p>
            </div>
            <hr />
            <div className="cartitems-total-item-place">
              <h3>Total</h3>
              <h3>₹{gettotalcartamount()}</h3>
            </div>
          </div>
          <button type="submit">Proced to Payment</button>
        </div>
      </div>
    </form>
  )
}

export default Addresscheckout
