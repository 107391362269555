import React, { createContext, useEffect, useState} from "react";

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {

    const [all_product,setall_product] = useState([]);
    const [cartitems,setcartitems] = useState({});
    const [cart, setCart] = useState([]);

    useEffect(()=>{
        fetch('http://localhost:4000/allproducts')
        .then((response)=>response.json())
        .then((data)=>setall_product(data))
    },[]);

    const addtocart = (itemId) => {
        const productToAdd = all_product.find(product => product.id === itemId);
        if (!productToAdd) return;
        setcartitems((prev) => ({
            ...prev,
            [itemId]: prev[itemId] ? prev[itemId] + 1 : 1
        }));
        setCart((prevCart) => [...prevCart, { ...productToAdd }]);
    };
    
    const removefromcart = (itemId) => {
        setcartitems((prev) => {
            const updatedCart = { ...prev };
            if (updatedCart[itemId] && updatedCart[itemId] > 0) {
                updatedCart[itemId]--;
            }
            return updatedCart;
        });
        setCart((prevCart) => {
            const updatedCart = [...prevCart];
            const index = updatedCart.findIndex(item => item.id === itemId);
            if (index !== -1) {
                if (updatedCart[index].quantity > 1) {
                    updatedCart[index].quantity--;
                } else {
                    updatedCart.splice(index, 1);
                }
            }
            return updatedCart;
        });
    };

    const gettotalcartamount = () => {
        let totalamount = 0;
        for(const item in cartitems){
            if(cartitems[item]>0){
                let iteminfo = all_product.find((product)=>product.id===Number(item));
                if (iteminfo && iteminfo.new_price) {
                    totalamount += iteminfo.new_price * cartitems[item];
                }
            }
        }
        return totalamount;
    }

    const gettotalcartitems = () => {
        let totalitem = 0;
        for(const item in cartitems){
            if(cartitems[item]>0){
                totalitem += cartitems[item];
            }
        }
        return totalitem;
    }
    

    const contextvalue = {gettotalcartamount,all_product,cartitems,addtocart,removefromcart,gettotalcartitems,cart};


    return (
        <ShopContext.Provider value={contextvalue}>
            {props.children}
        </ShopContext.Provider>
    )
}

export default ShopContextProvider;