import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import Footer from './Components/Footer/Footer';
import shrits_baneer from './Components/Assets/banner.png';
import shoes_baneer from './Components/Assets/banner.png';
import items_baneer from './Components/Assets/banner.png';
import Addresscheckout from './Components/Addresscheckout/Addresscheckout';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Shop/>}/>
          <Route path='/shrits' element={<ShopCategory banner={shrits_baneer} category="shrits" />}/>
          <Route path='/shoes' element={<ShopCategory banner={shoes_baneer} category="shoes" />}/>
          <Route path='/items' element={<ShopCategory banner={items_baneer} category="items" />}/>
          <Route path='/product' element={<Product/>}>
            <Route path=':productId' element={<Product/>}/>
          </Route>
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/checkout' element={<Addresscheckout/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;