import React, { useContext} from 'react'
import {Link} from "react-router-dom"
import './Cartitem.css'
import { ShopContext } from '../../Context/ShopContext'
import delete_icon from '../Assets/delete.svg'

const Cartitem = () => {

  const {gettotalcartamount,all_product,cartitems,removefromcart,cart} = useContext(ShopContext);
  const isCartEmpty = cart.length === 0;

  return (
    <div className='cartitems'>
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Tittle</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {all_product.map((e)=>{
        if(cartitems[e.id]>0){
            return <div key={e.id}>
            <div className="cartitem-format cartitems-format-main">
                <img src={e.image_urls[0]} alt="product" className='carticon-product-icon'/>
                <p>{e.name}</p>
                <p>₹{e.new_price}</p>
                <button className='cartitems-qunatity'>{cartitems[e.id]}</button>
                <p>₹{e.new_price*cartitems[e.id]}</p>
                <img className='cartitems-remove-icon' src={delete_icon} alt="" onClick={()=>{removefromcart(e.id)}}/>
            </div>
            <hr />
          </div>
        }
        return null;
      })}
      <div className="cartitems-down">
        <div className="cartitems-total">
            <h1>cart Totals</h1>
            <div>
                <div className="cartitems-total-item">
                    <p>Subtotal</p>
                    <p>₹{gettotalcartamount()}</p>
                </div>
                <hr />
                <div className="cartitems-total-item">
                    <p>Shipping fee</p>
                    <p>Free</p>
                </div>
                <hr />
                <div className="cartitems-total-item">
                    <h3>Total</h3>
                    <h3>₹{gettotalcartamount()}</h3>
                </div>
            </div>
            <button disabled={isCartEmpty}><Link to="/checkout">Proced to click</Link></button>
        </div>
      </div>
    </div>
  )
}

export default Cartitem
