import React, { useContext, useRef} from 'react'
import './Navbar.css'
import cart from '../Assets/cart2.svg'
import { Link, useLocation } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import menu_nav_down from '../Assets/menu_down.svg';

const Navbar = () => {

    const { pathname } = useLocation();
    const {gettotalcartitems} = useContext(ShopContext);
    const menuRef = useRef();

    const dropdown_toggle = (e) =>{
      menuRef.current.classList.toggle('nav-menu-visible');
      e.target.classList.toggle('open')
    }

  return (
    <div className='navbar'>
    <img className='nav-dropdown' onClick={dropdown_toggle} src={menu_nav_down} alt="menu" />
      <div className="nav-logo">Rajasthan Military store</div>
      <ul ref={menuRef} className="nav-menu">
        <li><Link to='/'>Shop</Link>{pathname === '/' && <hr />}</li>
        <li><Link to='/shrits'>Shrits</Link>{pathname === '/shrits' && <hr />}</li>
        <li><Link to='/shoes'>Shoes</Link>{pathname === '/shoes' && <hr />}</li>
        <li><Link to='/items'>Items</Link>{pathname === '/items' && <hr />}</li>
      </ul>
      <div className="nav-login-cart">
        <Link to='/cart'><img src={cart} alt="cart" /></Link>
        <div className="nav-cart-count">{gettotalcartitems()}</div>
      </div>
    </div>
  )
}

export default Navbar