import React, { useEffect, useState } from 'react'
import './Popular.css'
import Item from '../Item/Item'

const Popular = () => {
  

  const [popularproducts,setpopularproducts] = useState([]);

  useEffect(()=>{
    fetch('http://localhost:4000/popularinwomen')
    .then((response)=>response.json())
    .then((data)=>setpopularproducts(data))
  },[])

  return (
    <div className='popular'>
      <h1>POPULAR IN SHRITS</h1>
      <hr />
      <div className="popular-item">
        {popularproducts.map((item,i)=>{
            return <Item key={i} id={item.id} name={item.name} image_urls={item.image_urls[0]} new_price={item.new_price} old_price={item.old_price}/>
        })}
      </div>
    </div>
  )
}

export default Popular
