import React, { useContext, useState } from 'react'
import './ProductDisplay.css'
import star from '../Assets/star.svg';
import stardull from '../Assets/star_dull.svg'
import { ShopContext } from '../../Context/ShopContext';

const ProductDisplay = (props) => {
    const {product} = props;
    const {addtocart} = useContext(ShopContext);
    const [image,setimage] = useState(product.image_urls[0])
    const [imageClasses, setImageClasses] = useState(Array(product.image_urls.length).fill(''));

    const handleImageClick = (index) => {
      setimage(product.image_urls[index]);
      const updatedClasses = Array(product.image_urls.length).fill('');
      updatedClasses[index] = 'blue-border';
      setImageClasses(updatedClasses);
    };

  return (
    <div className='productdisplay'>
      <div className="productdisplay-left">
        <div className="productdisplay-img-lists">
          {product.image_urls.map((url, index) => (
            <img key={index} onClick={() => handleImageClick(index)} src={url} alt="itemimage" className={imageClasses[index]} /> 
          ))}
        </div>
        <div className="productdisplay-img">
            <img className='productdisplay-main-img' src={image} alt="itemimage" />
        </div>
      </div>
      <div className="productdisplay-right">
        <h1>{product.name}</h1>
        <div className="productdisplay-right-star">
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={stardull} alt="star" />
            <p>(122)</p>
        </div>
        <div className="productdisplay-right-prices">
            <div className="productdisplay-right-price-old">₹{product.old_price}</div>
            <div className="productdisplay-right-price-new">₹{product.new_price}</div>
        </div>
        <div className="productdisplay-right-description">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aspernatur ab veniam neque officiis quis. Iusto tempore minima eaque esse rem.
        </div>
        <div className="productdisplay-right-size">
            <h1>Select Size</h1>
            <div className="productdisplay-right-sizes">
                <div>S</div>
                <div>M</div>
                <div>L</div>
                <div>XL</div>
                <div>XXL</div>
            </div>
        </div>
        <button onClick={()=>{addtocart(product.id)}}>ADD TO CART</button>
        <p className='productdisplay-right-category'><span>Category :</span>Women , T-Shrit , Crop Top</p>
        <p className='productdisplay-right-category'><span>Tags :</span>Modern, Latest</p>
      </div>
    </div>
  )
}

export default ProductDisplay
