import React from 'react'
import Cartitem from '../Components/Cartitem/Cartitem'
import './css/Cart.css'

const Cart = () => {
  return (
    <div>
      <Cartitem />
     </div>
  )
}

export default Cart
