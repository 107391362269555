import React from 'react'
import './DescriptionBox.css'

const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box">Description</div>
        <div className="descriptionbox-nav-box fade">Reviews (122)</div>
      </div>
      <div className="descriptionbox-description">
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga aliquid sapiente omnis totam! Incidunt ullam temporibus quod, dolore nemo et eum reiciendis eaque ratione facere impedit, deleniti, deserunt repellat provident quis esse soluta maxime cupiditate officiis voluptatem odio distinctio doloremque. Alias eaque dolor praesentium laboriosam, facere dolore consequatur, nulla at et optio ducimus tempore nisi repudiandae illo veniam iusto eius possimus eum porro excepturi vero assumenda iste veritatis aspernatur. Distinctio molestiae perferendis veniam ipsam vero sequi quaerat commodi fuga dolorem similique excepturi et officia laborum unde magnam, temporibus</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste odio sit maiores, aperiam labore sed, deserunt vel iure cupiditate nihil qui modi quis consequatur officia, incidunt nemo perferendis. Adipisci, mollitia?</p>
      </div>
    </div>
  )
}

export default DescriptionBox
